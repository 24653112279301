import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { RiProfileFill } from "react-icons/ri";
import { FaRegArrowAltCircleRight } from "react-icons/fa";
import { FaPeoplePulling } from "react-icons/fa6";
import { MdOutlineDisplaySettings } from "react-icons/md";
import { MdOutlinePendingActions } from "react-icons/md";

const AdminNavigation = ({user}) => {

    
    return (
        <Fragment>
        <div className="grid-section-main roboto-regular">
                <div className="gsm-title" ></div>

                <div className="nav-gsm-content">
                    <Link to="/comingsoon" className="nav-gsm-items">
                        <span><RiProfileFill /></span>
                        <span>Company Profile</span>
                        <span><FaRegArrowAltCircleRight /></span>
                    </Link>

                    <Link to="/admin/edit-company" className="nav-gsm-items">
                        <span><MdOutlineDisplaySettings />
                        </span>
                        <span>Company Settings</span>
                        <span><FaRegArrowAltCircleRight /></span>
                    </Link>
                    
                    <Link to="/leave-setting" className="nav-gsm-items">
                        <span><MdOutlinePendingActions /></span>
                        <span>Pending Leave</span>
                        <span><FaRegArrowAltCircleRight /></span>
                    </Link>

                    <Link to="/people" className="nav-gsm-items">
                        <span><FaPeoplePulling /></span>
                        <span>My People</span>
                        <span><FaRegArrowAltCircleRight /></span>
                    </Link>
        
                   
                </div>
        </div>
        </Fragment>
    );
};


export default AdminNavigation;
