import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { MdOutlineMarkEmailRead, MdOutlinePhoneInTalk } from "react-icons/md";
import { PiDotsThreeCircleLight } from "react-icons/pi";
import { LuUserCircle2 } from "react-icons/lu";
import { RiFolderUserLine } from "react-icons/ri";
import { LiaUserEditSolid } from "react-icons/lia";

const DEFAULT_AVATAR = '/path-to-default-avatar.jpg'; // Move to a constant if used elsewhere

const OurPeople = ({ users, admin }) => {
    const [dropdownOpen, setDropdownOpen] = useState(null); // Manage dropdown state for each user

    // Function to determine the correct avatar URL
    const loadAvatar = (user) => {
        if (user) {
            if (user.avatar && user.avatar.startsWith('//www.gravatar.com/avatar/')) {
                // Use the Gravatar URL directly
                return `https:${user.avatar}`;
            } else if (user.avatarUrl) {
                // Use the presigned URL directly
                return user.avatarUrl;
            } else {
                // Default avatar if no avatar URL is present
                return DEFAULT_AVATAR;
            }
        } else {
            // Default avatar if no user
            return DEFAULT_AVATAR;
        }
    };

    // Function to toggle the dropdown menu
    const toggleDropdown = (userId) => {
        setDropdownOpen(prev => prev === userId ? null : userId);
    };

    // Determine if custom styles should be applied
    const containerStyle = users.length > 0 && users.length < 3 ? { maxWidth: '350px' } : {};

    return (
        <Fragment>
            <div className='people-grid-section roboto-regular'>
                {users.length > 0 ? (
                    users.map(user => (
                        <div
                            key={user._id}
                            className="grid-section-item"
                            style={containerStyle} // Apply styles conditionally
                        >
                            <div className="contact-item-img">
                                <img src={loadAvatar(user)} alt={user.name} />
                                <div className="gs-status"></div>
                            </div>
                            <div className="gs-link-btn" onClick={() => toggleDropdown(user._id)}>
                                <PiDotsThreeCircleLight />
                                {dropdownOpen === user._id && (
                                    <div className="dropdown-menu" id="people-dropdown-menu">
                                        <ul>
                                            <li><Link to={`/user-profile/${user._id}`} className="rm-decor"><LuUserCircle2 /></Link></li>
                                            {admin?.role === 'admin' && (
                                                <Fragment>
                                                    <li><Link to={`/admin/${user._id}`} className="rm-decor"><RiFolderUserLine /></Link></li>
                                                    
                                                    <li><Link to={`/admin/ed/${user._id}`} className="rm-decor"><LiaUserEditSolid /></Link></li>
                                                </Fragment>
                                            )}
                                        </ul>
                                    </div>
                                )}
                            </div>  
                            <div className="contact-item-particulars">
                                <div className="ci-name">{user.name}</div>
                                <div className='ci-position'>{user.jobposition}</div>
                            </div>
                            <div className="contact-item-details">
                                <div className="ci-work-particulars">
                                    <div>
                                        <span className="ci-wp-title">Department</span>
                                        <span className="ci-wp-desc">{user.department}</span>
                                    </div>
                                    <div>
                                        <span className="ci-wp-title">Hired Date</span>
                                        <span className="ci-wp-desc">
                                            {user.hiredDate ? new Date(user.hiredDate).toLocaleDateString('en-GB') : '-'}
                                        </span>
                                    </div>

                                </div>
                                <div className="ci-email">
                                    <span><MdOutlineMarkEmailRead /></span>
                                    <span>{user.email}</span>
                                </div>
                                <div className="ci-email">
                                    <span><MdOutlinePhoneInTalk /></span>
                                    <span>{user.contact}</span>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div>No users available</div>
                )}
            </div>
        </Fragment>
    );
};

OurPeople.propTypes = {
    users: PropTypes.array.isRequired,
};

export default OurPeople;
