import React, { Fragment, useEffect, useState } from 'react';
import { getProfile } from '../../actions/profile'; // Adjust the path based on your project structure
import { useParams, useNavigate, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';

const getLatestCareerEntry = (careers) => {
  if (!careers || !careers.length) return null;

  // Create a shallow copy of the careers array to avoid mutation
  const careersCopy = [...careers];

  return careersCopy
    .sort((a, b) => {
      if (a.current && b.current) return new Date(b.from) - new Date(a.from);
      if (a.current) return -1;
      if (b.current) return 1;

      const dateA = a.to ? new Date(a.to) : new Date(0);
      const dateB = b.to ? new Date(b.to) : new Date(0);

      return dateB - dateA;
    })[0];
};


const UserProfile = ({getProfile, auth: {user}, profile: {profile, loading}}) => {
  const { id } = useParams(); // Get the user ID from the URL params
  const navigate = useNavigate();

  const [activeOption, setActiveOption] = useState('option1');
  const [avatarUrl, setAvatarUrl] = useState('/path-to-default-avatar.jpg');

  const loadAvatar = () => {
    if (profile && profile.user) {
      if (profile.user.avatar && profile.user.avatar.startsWith('//www.gravatar.com/avatar/')) {
        // Use the Gravatar URL directly
        setAvatarUrl(`https:${profile.user.avatar}`);
      } else if (profile.user.avatarUrl) {
        // Use the presigned URL directly
        setAvatarUrl(profile.user.avatarUrl);
      } else {
        // Default avatar if no avatar URL is present
        setAvatarUrl('/path-to-default-avatar.jpg');
      }
    } else {
      // Default avatar if profile or profile.user is not available
      setAvatarUrl('/path-to-default-avatar.jpg');
    }
  };
  

  useEffect(() => {
    let isMounted = true; // Track if the component is still mounted
  
    const fetchProfile = async () => {
      try {
        await getProfile(id);
        // Only update state if the component is still mounted
        if (isMounted) {
          // Profile data would be handled by Redux, no need for local state updates here
        }
      } catch (err) {
        console.log(err);
      }
    };
  
    fetchProfile();
  
    return () => {
      isMounted = false; // Cleanup function to prevent state updates if component is unmounted
    };
  }, [getProfile, id]);
  
  
  useEffect(() => {
    loadAvatar();
  }, [profile]); // This ensures loadAvatar is called when profile changes
  

  if (loading || profile === null) {
    return <Spinner />;
  }

  if (!user) {
    return <Spinner />;
  }

  if (!profile.company) {
    return <Spinner />;
  }

  if (profile === null) {
    return <div>Profile don't exist</div>
  }

    // Handler to set active option
    const handleOptionClick = (option) => {
      setActiveOption(option);
    };

     // Function to determine the button style
    const getButtonStyle = (option) => {
      return {
        backgroundColor: activeOption === option ? 'rgb(52, 89, 154)' : 'transparent',
        color: activeOption === option ? 'white' : 'black',
      };
    };  

  const latestCareer = getLatestCareerEntry(profile.career);

  return (
    <Fragment>
      {user && profile ? (
        <main className='roboto-regular pfp-main'>
        <div className="pfp-hero">

        <div className="hero-img">
          <img
            src={
              profile.company.image && profile.company.image.startsWith('http://placehold.co')
                ? 'https://placehold.co/600x400/000000/FFFFFF/png'
                : profile.company.companyUrl || 'https://placehold.co/600x400/000000/FFFFFF/png'
            }
            alt='company-logo'
            onError={(e) => { e.target.src = 'https://placehold.co/600x400/000000/FFFFFF/png'; }} 
          />
        </div>

          <div className="userpfp-img">
            <img src={avatarUrl} alt='user-img' />
          </div>

          <div className="userpfp-sm-links">
            <div className="sm-links">
              {profile.social.facebook && (
                <a href={profile.social.facebook} className="sm-links-img" target="_blank" rel="noopener noreferrer">
                  <img src="/images/fb-icon.png" alt="facebook" />
                </a>
              )}

              {profile.social.linkedin && (
                <a href={profile.social.linkedin} className="sm-links-img" target="_blank" rel="noopener noreferrer">
                  <img src="/images/linkedin-icon.png" alt="linkedin" />
                </a>
              )}

              {profile.social.youtube && (
                <a href={profile.social.youtube} className="sm-links-img" target="_blank" rel="noopener noreferrer">
                  <img src="/images/icon-yt.png" alt="youtube" />
                </a>
              )}

              {profile.social.instagram && (
                <a href={profile.social.instagram} className="sm-links-img" target="_blank" rel="noopener noreferrer">
                  <img src="/images/ig.png" alt="instagram" />
                </a>
              )}
            </div>
          </div>


          <div className="userpfp-pd">
            <div className="userpfp-pd-user">
              <div className="userpfp-name">{profile.user.name}</div>
              <div className="userpfp-role">{profile.user.jobposition}</div>
              <div className="userpfp-innercont">
                <span className="userpfp-location">{profile.location}</span>
              {/*}
                <span className="userpfp-link">&#x27A4; &nbsp; Contact info</span>
              */}
              </div>

              
              {user._id !== profile.user._id && (
                <div className="userpfp-innercont-btn">
                  <div className="userpfp-action-btn">&#x2b; Connect</div>
                  <div>Message</div>
                </div>
              )}

            </div>

          {/*}
            <div className="userpfp-pd-compedu">
              <div className="userpfp-compedu-cont">
                <div className="userpfp-compedu-img">
                  <img src="/images/acr-logo.png" alt="company-logo" />
                </div>
                <div className="userpfp-compedu-name"> {latestCareer ? latestCareer.company : ''} </div>
              </div>

              <div className="userpfp-compedu-cont">
                <div className="userpfp-compedu-img">
                  <img src="/images/uol-logo.jpg" alt="company-logo" />
                </div>
                <div className="userpfp-compedu-name">University of London </div>
              </div>

            </div>
            */}
          </div>   

        </div>
        
        <div className="userpfp-main">

          <div className="userpfp-act-links">
            <div onClick={() => handleOptionClick('option1')}className="userpfp-act-links-btn" style={getButtonStyle('option1')}>About Me</div>
            <div onClick={() => handleOptionClick('option2')} className="userpfp-act-links-btn" style={getButtonStyle('option2')}>Portfolio</div>
          </div>

          <div className="userpfp-links-main">
              
              {activeOption === 'option1' && (
                <div>
                  {user._id === profile.user._id && profile.about === '' && (
                    <div>
                       <div>
                        <h2>Your profile is missing some key information.</h2>
                      </div>

                      <div className="userpfp-innercont-btn">
                        
                          <Link to={`/edit-profile/${user._id}`} id="update-pfp-btn">Update Profile</Link>
                        
                      </div>
                    </div>
                  )}
                    <div className='userpfp-about1'>{profile.about}</div>
                </div>
                )}

                {activeOption === 'option2' && (
                  <div>
                    <h2>No Portfolio</h2>
                    <br/>
                    <p>Portfolio will be automatically added on completion of existing projects and work task</p>
                  </div>
                )}

            </div>

        </div>

      </main>
      ) : (<div><Spinner /></div>)}
    </Fragment>
  );              
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  auth: state.auth
});

export default connect(mapStateToProps, {getProfile})(UserProfile);
