import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { fetchPendingLeaves } from '../../actions/leave';

import { IoAirplaneOutline } from "react-icons/io5";
import { CiMedicalCross } from "react-icons/ci";
import { PiAmbulanceBold } from "react-icons/pi";
import { LuBaby } from "react-icons/lu";

const LeaveDashboard = ({ fetchPendingLeaves, leave: { leaves, loading }, user }) => {
    // Track used leave for different types
    const [usedAnnualLeave, setUsedAnnualLeave] = useState(0);
    const [usedMedicalLeave, setUsedMedicalLeave] = useState(0);
    const [usedHospitalLeave, setUsedHospitalLeave] = useState(0);
    const [usedMaternityLeave, setUsedMaternityLeave] = useState(0);

    useEffect(() => {
        fetchPendingLeaves();

        if (user?.leave) {
            // Calculate used leaves for each type
            const initialAnnual = user.leave.initialAnnual || 0;
            const currentAnnual = user.leave.annual || 0;

             // Log values for debugging
            console.log('initialAnnual:', initialAnnual);
            console.log('currentAnnual:', currentAnnual);


            setUsedAnnualLeave(Math.max(initialAnnual - currentAnnual));

            const initialMedical = user.leave.initialMedical || 0;
            const currentMedical = user.leave.medical || 0;
            setUsedMedicalLeave(Math.max(initialMedical - currentMedical, 0));

            const initialHospitalisation = user.leave.initialhospitalisation || 0;
            const currentHospitalisation = user.leave.hospitalisation || 0;
            setUsedHospitalLeave(Math.max(initialHospitalisation - currentHospitalisation, 0));

            const initialMaternity = user.leave.initialMaternity || 0;
            const currentMaternity = user.leave.maternity || 0;
            setUsedMaternityLeave(Math.max(initialMaternity - currentMaternity, 0));
        }
    }, [fetchPendingLeaves, user]);

    if (loading || !user?.leave) {
        return <div>Loading...</div>;
    }

    return (
        <Fragment>
            <div className="grid-section-main roboto-regular">
                <div className="gsm-title"></div>

                <div className="ld-gsm-content">
                    {/* Annual Leave Section */}
                    <section>
                        <div className="ld-icon">
                            <div className="ld-icon-circle"><IoAirplaneOutline /></div>
                            <div>Annual</div>
                        </div>
                        <div className="ld-desc">
                            <div className="ld-desc-used">
                                <div>Used</div>
                                <div>{usedAnnualLeave}</div> 
                            </div>
                            <div className="ld-desc-available">
                                <div>Available</div>
                                <div>{user.leave.annual}</div> 
                            </div>
                        </div>
                    </section>

                    {/* Medical (Sick) Leave Section */}
                    <section>
                        <div className="ld-icon">
                            <div className="ld-icon-circle"><CiMedicalCross /></div>
                            <div>Sick</div>
                        </div>
                        <div className="ld-desc">
                            <div className="ld-desc-used">
                                <div>Used</div>
                                <div>{usedMedicalLeave}</div> 
                            </div>
                            <div className="ld-desc-available">
                                <div>Available</div>
                                <div>{user.leave.medical}</div> 
                            </div>
                        </div>
                    </section>

                    {/* Hospitalisation Leave Section */}
                    <section>
                        <div className="ld-icon">
                            <div className="ld-icon-circle"><PiAmbulanceBold /></div>
                            <div>Hospital</div>
                        </div>
                        <div className="ld-desc">
                            <div className="ld-desc-used">
                                <div>Used</div>
                                <div>{usedHospitalLeave}</div> 
                            </div>
                            <div className="ld-desc-available">
                                <div>Available</div>
                                <div>{user.leave.hospitalisation}</div> 
                            </div>
                        </div>
                    </section>

                    {/* Maternity Leave Section */}
                    <section>
                        <div className="ld-icon">
                            <div className="ld-icon-circle"><LuBaby /></div>
                            <div>{user.gender === 'Male' ? 'Paternity' : 'Maternity'}</div>

                        </div>
                        <div className="ld-desc">
                            <div className="ld-desc-used">
                                <div>Used</div>
                                <div>{usedMaternityLeave}</div> 
                            </div>
                            <div className="ld-desc-available">
                                <div>Available</div>
                                <div>{user.leave.maternity}</div> 
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </Fragment>
    );
};

const mapStateToProps = (state) => ({
    leave: state.leave,
    user: state.auth.user // Assuming user is in the auth state
});

export default connect(mapStateToProps, { fetchPendingLeaves })(LeaveDashboard);
