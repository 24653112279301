// src/action/types.js
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILES = "GET_PROFILES";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const PROFILE_ERROR = "PROFILE_ERROR";
export const GET_USERS = "GET_USERS";
export const GET_USER = "GET_USER";
export const USERS_ERROR = "USERS_ERROR";
export const FETCH_PUBLIC_HOLIDAYS = "FETCH_PUBLIC_HOLIDAYS";
export const FETCH_PH_ERROR = "FETCH_PH_ERROR";
export const FETCH_PENDING_LEAVES = "FETCH_PENDING_LEAVES";
export const FETCH_LEAVE_RECORDS = "FETCH_LEAVE_RECORDS";
export const FETCH_LEAVES_ERROR = "FETCH_LEAVES_ERROR";
export const PERSONAL_DETAILS_UPDATE_SUCCESS = 'PERSONAL_DETAILS_UPDATE_SUCCESS';
export const PERSONAL_DETAILS_UPDATE_FAIL = 'PERSONAL_DETAILS_UPDATE_FAIL';
export const GET_COMPANY = 'GET_COMPANY';
export const COMPANY_ERROR = 'COMPANY_ERROR';
export const RESETPASSWORD_SUCCESS = "RESETPASSWORD_SUCCESS";
export const RESETPASSWORD_FAIL = "RESETPASSWORD_FAIL";
export const RESETPASSWORD_REQUEST = 'RESETPASSWORD_REQUEST';
