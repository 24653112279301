import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getUsers } from '../../actions/users';
import Spinner from '../layout/Spinner';

import { BsArrowUpRightCircle } from "react-icons/bs";

const Team = ({ getUsers, users: { users, loading } }) => {
    const [displayedUsers, setDisplayedUsers] = useState([]);

    // Function to determine the correct avatar URL
    const loadAvatar = (user) => {
        if (user) {
            if (user.avatar && user.avatar.startsWith('//www.gravatar.com/avatar/')) {
                return `https:${user.avatar}`;
            } else if (user.avatarUrl) {
                return user.avatarUrl;
            } else {
                return '/path-to-default-avatar.jpg';
            }
        } else {
            return '/path-to-default-avatar.jpg';
        }
    };

    // Shuffle array function
    const shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };

    // Memoize updateDisplayedUsers to prevent unnecessary re-renders
    const updateDisplayedUsers = useCallback(() => {
        if (users.length > 0) {
            const shuffledUsers = shuffleArray([...users]);
            setDisplayedUsers(shuffledUsers.slice(0, 4));
        }
    }, [users]);

    useEffect(() => {
        getUsers();
    }, [getUsers]);

    useEffect(() => {
        updateDisplayedUsers();
    }, [users, updateDisplayedUsers]);

    // Update users every hour
    useEffect(() => {
        const intervalId = setInterval(() => {
            updateDisplayedUsers();
        }, 3600000); // 3600000 ms = 1 hour

        return () => clearInterval(intervalId);
    }, [updateDisplayedUsers]);

    if (loading) {
        return <Spinner />;
    }

    return (
        <Fragment>
            <div className="grid-section-main roboto-regular">
                <div className="gsm-title" >
                    <div className="adm-db-people-title">People</div>
                    <div><Link to="/people" className="icon-reset"><BsArrowUpRightCircle /></Link></div>
                </div>

                <div className="teams-gsm-content">
                    {displayedUsers.length > 0 ? (

                        <div className="teams-user-list">
                            {displayedUsers.map(user => (
                                <Link to={`/user-profile/${user._id}`} key={user._id} className="teams-user-item">
                                    <img 
                                        src={loadAvatar(user)} 
                                        alt={user.name} 
                                        className="teams-user-avatar" 
                                    />
                                    <div className="teams-user-info">
                                        <div>{user.name}</div>
                                        <div>{user.jobposition}</div>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    ) : (
                        <p>No users found</p>
                    )}
                </div>
            </div>
        </Fragment>
    );
};

Team.propTypes = {
    getUsers: PropTypes.func.isRequired,
    users: PropTypes.shape({
        users: PropTypes.array.isRequired,
        loading: PropTypes.bool.isRequired
    }).isRequired
};

const mapStateToProps = (state) => ({
    users: state.users
});

export default connect(mapStateToProps, { getUsers })(Team);
