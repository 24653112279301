import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import Spinner from '../layout/Spinner';
import { getUser } from '../../actions/users';
import { fetchPublicHolidays } from '../../actions/leave';
import setAuthToken from '../../utils/setAuthToken';
import axios from 'axios';

const LeaveForm = ({ getUser, fetchPublicHolidays, user, loading, publicHolidays }) => {
  const { id } = useParams(); // Retrieve the user ID from URL parameters
  const navigate = useNavigate(); // Hook to programmatically navigate
  
  // Fetch user data and public holidays when component mounts
  useEffect(() => {
    getUser(id);
    fetchPublicHolidays();
  }, [getUser, id, fetchPublicHolidays]);

  // State for form fields and calculations
  const [selectedOption, setSelectedOption] = useState('annual');
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [daysBetween, setDaysBetween] = useState('');
  const [reason, setReason] = useState('');
  const [halfDayOption, setHalfDayOption] = useState('');
  const [file, setFile] = useState(null); 

  // Check if a date is a weekend
  const isWeekend = (date) => {
    const day = date.getDay();
    return day === 6 || day === 0; // Saturday or Sunday
  };

  // Check if a date is a public holiday
  const isPublicHoliday = (date) => {
    return publicHolidays.some((holiday) => {
      const holidayDate = new Date(holiday.date);
      return holidayDate.toDateString() === date.toDateString();
    });
  };

  // Validate if a date is suitable for half-day leave
  const validateHalfDay = (date) => {
    return !(isWeekend(date) || isPublicHoliday(date));
  };

  // Calculate the number of working days between two dates
  const calculateDaysBetween = (fromDate, toDate) => {
    let startDate = new Date(fromDate);
    let endDate = new Date(toDate);
    
    // Ensure valid dates
    if (isNaN(startDate) || isNaN(endDate)) return '';
  
    if (startDate.toDateString() === endDate.toDateString()) {
      // Same date, check if it's a valid half-day
      if (validateHalfDay(startDate)) {
        return '0.5';
      }
      return '';
    }
  
    let days = 0;
    while (startDate <= endDate) {
      if (!isWeekend(startDate) && !isPublicHoliday(startDate)) {
        days++;
      }
      startDate.setDate(startDate.getDate() + 1);
    }
  
    return days > 0 ? days - 1 : ''; // Adjust for inclusive end date
  };

  // Handle changes in date inputs
  const handleDateChange = (event) => {
    const { name, value } = event.target;

    if (name === 'dateFrom') {
      setDateFrom(value);
      if (dateTo) {
        const newDaysBetween = calculateDaysBetween(value, dateTo);
        setDaysBetween(newDaysBetween);
      }
    } else if (name === 'dateTo') {
      setDateTo(value);
      if (dateFrom) {
        const newDaysBetween = calculateDaysBetween(dateFrom, value);
        setDaysBetween(newDaysBetween);
      }
    }
  };

  // Handle changes in leave type radio buttons
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  // Handle changes in reason textarea
  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };

  // Handle changes in half-day option radio buttons
  const handleHalfDayChange = (event) => {
    setHalfDayOption(event.target.value);
    if (dateFrom === dateTo && validateHalfDay(new Date(dateFrom))) {
      setDaysBetween('0.5');
    }
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]); // Get the first file
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if the selected leave type is "annual"
    if (selectedOption === 'annual') {
      const today = new Date();
      const leaveDate = new Date(dateFrom);
      const daysInAdvance = Math.ceil((leaveDate - today) / (1000 * 60 * 60 * 24));

      // Apply the 7-day rule for annual leave only
      if (daysInAdvance < 7) {
        alert('Please apply for annual leave at least 7 days in advance.');
        return; // Prevent submission if the rule is violated
      }
    }
    
    setAuthToken(localStorage.getItem('token'));
    
    // Create a FormData object to handle form data including the file
    const formData = new FormData();
    formData.append('user', user._id);
    formData.append('leaveType', selectedOption);
    formData.append('dateFrom', dateFrom);
    formData.append('dateTo', dateTo);
    formData.append('daysBetween', daysBetween);
    formData.append('reason', reason);
    formData.append('company', user.company?.companyName);

    if (daysBetween === '0.5') {
      formData.append('halfDayOption', halfDayOption);
    }

    if (file) {
      formData.append('image', file); // Append the file if it exists
    }

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      const res = await axios.post('/api/leave/apply-leave', formData, config);
      
      if (res.status === 200 || res.status === 201) {
        alert('Your Leave Application has been successfully submitted!');
        navigate('/dashboard');
      } else {
        console.error('Failed to submit leave:', res.data);
      }
    } catch (error) {
      console.error('Error submitting leave:', error);
      alert('An error occurred while submitting your leave. Please try again.');
    }
  };

  // Show spinner if data is loading or user is not available
  if (loading || !user) return <Spinner />;

  return (
    <Fragment>
      <div className="container roboto-regular">
        <div className="leaveform-wrapper">
          <div className="leaveform-header roboto-bold">Leave Application Form</div>
          <div className="leaveform-company-header">{user.company?.companyName}</div>
          <form className="leaveform-content-container" onSubmit={handleSubmit}>

            <div>Employee: {user.name}</div>
            <br/>

            <div>Type of Leave Applied: (Please select one)</div>

            <div className="radio-container">
              {['annual', 'medical', 'emergency', 'study', 'replacement', 'compassionate', 'marriage', 'maternity', 'hospitalisation', 'unpaid'].map((type) => (
                <label key={type} className="leaveform-radio">
                  {type.charAt(0).toUpperCase() + type.slice(1).replace('isation', 'ization')} Leave
                  <input
                    type="radio"
                    name="leaveType"
                    value={type}
                    checked={selectedOption === type}
                    onChange={handleOptionChange}
                  />
                  <span className="checkmark"></span>
                </label>
              ))}
            </div>

            <div className="laf-date">

              <div>
                <span>Date From:</span>
                <input
                    className="laf-input"
                    type="date"
                    name="dateFrom"
                    value={dateFrom}
                    onChange={handleDateChange}
                    required
                  />
              </div>

              <div>
                <span>Date To:</span>
                <input
                    className="laf-input"
                    type="date"
                    name="dateTo"
                    value={dateTo}
                    onChange={handleDateChange}
                    min={dateFrom}
                    required
                  />
              </div>


            </div>

            {dateFrom && dateTo && dateFrom === dateTo && (
              <div className="laf-radio-container">
                <div>Select Half Day Option:</div>
                <br/>
                <label className="leaveform-radio">
                  9am - 1pm
                  <input
                    type="radio"
                    name="halfDayOption"
                    value="9am-1pm"
                    checked={halfDayOption === '9am-1pm'}
                    onChange={handleHalfDayChange}
                  />
                  <span className="checkmark"></span>
                </label>
                <label className="leaveform-radio">
                  2pm - 6pm
                  <input
                    type="radio"
                    name="halfDayOption"
                    value="2pm-6pm"
                    checked={halfDayOption === '2pm-6pm'}
                    onChange={handleHalfDayChange}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            )}

            <div className="laf-textbox">
              <span>Number of Days:</span>
              <input
                  className="laf-input"
                  type="text"
                  name="daysBetween"
                  value={daysBetween}
                  readOnly
                />
            </div>
              
            <div className="laf-textbox">
              <span>Reason</span>
              <textarea
                  className="laf-input custom-textarea"
                  name="reason"
                  value={reason}
                  onChange={handleReasonChange}
                  required
                />
            </div>

            <div className="laf-file-upload">
              <label>Attach Supporting Document (if any): &nbsp;</label>
              <input 
                type="file"
                name="image" 
                onChange={handleFileChange} 
              />
            </div>

            <br/>

            {daysBetween > 0 && (
              <button type="submit" className="submit-btn roboto-bold">Submit</button>
            )}

          </form>
        </div>
      </div>
    </Fragment>
  );
};

LeaveForm.propTypes = {
  getUser: PropTypes.func.isRequired,
  fetchPublicHolidays: PropTypes.func.isRequired,
  user: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  publicHolidays: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.users.user,
  loading: state.users.loading,
  publicHolidays: state.leave.publicHolidays
});

export default connect(mapStateToProps, { getUser, fetchPublicHolidays })(LeaveForm);
