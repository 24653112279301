import React, { Fragment } from 'react';
import spinner from '../../assets/images/spinner.webp';

const Spinner = () => (
    <Fragment>
        <img
            src={spinner}
            style={{ margin: '5rem auto', display: 'block' }}
            alt="Loading..."
        />
    </Fragment>
);

export default Spinner;
