import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { RiProfileFill } from "react-icons/ri";
import { FaRegArrowAltCircleRight } from "react-icons/fa";
import { HiDocumentText } from "react-icons/hi2";
import { FaPeoplePulling } from "react-icons/fa6";
import { IoSettingsSharp } from "react-icons/io5";
import { MdAdminPanelSettings } from "react-icons/md";

const Navigation = ({user}) => {

    
    return (
        <Fragment>
        <div className="grid-section-main roboto-regular">
                <div className="gsm-title" ></div>

                <div className="nav-gsm-content">
                    <Link to={`/user-profile/${user._id}`} className="nav-gsm-items">
                        <span><RiProfileFill /></span>
                        <span>Profile</span>
                        <span><FaRegArrowAltCircleRight /></span>
                    </Link>
                    
                    <Link to={`/leaveform/${user._id}`} className="nav-gsm-items">
                        <span><HiDocumentText /></span>
                        <span>Apply Leave</span>
                        <span><FaRegArrowAltCircleRight /></span>
                    </Link>
                    <Link to="/people" className="nav-gsm-items">
                        <span><FaPeoplePulling /></span>
                        <span>My People</span>
                        <span><FaRegArrowAltCircleRight /></span>
                    </Link>
                    {user.role !== 'admin' ? (
                    <Link to={`/edit-profile/${user._id}`} className="nav-gsm-items">
                        <span><IoSettingsSharp /></span>
                        <span>Settings</span>
                        <span><FaRegArrowAltCircleRight /></span>
                    </Link>
                    ) : (
                    <Link to='/admin' className="nav-gsm-items">
                        <span><MdAdminPanelSettings /></span>
                        <span>Admin</span>
                        <span><FaRegArrowAltCircleRight /></span>
                    </Link>
                    )}

                </div>
        </div>
        </Fragment>
    );
};


export default Navigation;
