import React, { Fragment, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../../actions/auth';
import { Link, useNavigate } from 'react-router-dom';

const Navbar = ({ auth: { isAuthenticated, loading, user }, logout }) => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [isCentered, setIsCentered] = useState(false);
    const [avatarUrl, setAvatarUrl] = useState('/path-to-default-avatar.jpg');
    const dropdownRef = useRef(null);

    // Function to load avatar
    const loadAvatar = () => {
        if (user) {
            if (user.avatar && user.avatar.startsWith('//www.gravatar.com/avatar/')) {
                // Use the Gravatar URL directly
                setAvatarUrl(`https:${user.avatar}`);
            } else if (user.avatarUrl) {
                // Use the presigned URL directly
                setAvatarUrl(user.avatarUrl);
            } else {
                // Default avatar if no avatar URL is present
                setAvatarUrl('/path-to-default-avatar.jpg');
            }
        } else {
            // Default avatar if no user
            setAvatarUrl('/path-to-default-avatar.jpg');
        }
    };

    useEffect(() => {
        loadAvatar();
    }, [user]); // Only include user in the dependency array

    const handleLogout = () => {
        logout(); // Perform logout
        navigate('/'); // Redirect to login page
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
        setIsCentered(!isCentered);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
            setIsCentered(false);
        }
    };

    useEffect(() => {
        // Add event listener for clicks outside the dropdown
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Remove event listener on cleanup
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const authLinks = (
        <Fragment>
           <div className="dropdown-container" ref={dropdownRef}>
            <div 
                className={`pill ${isCentered ? 'center' : ''}`} 
                onClick={toggleDropdown}
            >
                <img 
                src={avatarUrl} 
                alt="User" 
                className="nav-userimg" 
                />
                <div className="pill-greeting">Hello!</div>
                <div className="pill-arrow">
                <img src="/images/pill-arrow.ico" alt="arrow" />
                </div>
            </div>
            {isOpen && (
                <div className="dropdown-menu">
                <ul>
                    <li><Link to={`/user-profile/${user._id}`} className="dm-link">Profile</Link></li>
                    <li><Link to={`/edit-profile/${user._id}`} className="dm-link">Settings</Link></li>
                    <li onClick={handleLogout}>Log Out</li>
                </ul>
                </div>
            )}
            </div>
        </Fragment>
    );                      

    const guestLinks = (
        <div></div>
    );

    return (
        <nav className='roboto-bold'>
            <div className="nav-left"></div>
            <div className="nav-right">
                { !loading && (<Fragment>{ isAuthenticated ? authLinks : guestLinks }</Fragment>)}
            </div>
        </nav>
    );
};

Navbar.propTypes = {
    logout: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { logout })(Navbar);
