import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchPendingLeaves } from '../../actions/leave';
import { BsArrowUpRightCircle } from "react-icons/bs";

const Leave = ({ fetchPendingLeaves, leave: { leaves, loading } }) => {

  useEffect(() => {
    fetchPendingLeaves();
  }, [fetchPendingLeaves]);

// Function to determine the correct avatar URL
const loadAvatar = (user) => {
    if (user) {
        // Check for Gravatar URL and ensure it's properly formatted
        if (user.avatar && user.avatar.startsWith('//www.gravatar.com/avatar/')) {
            return `https:${user.avatar}`;
        } 
        // Check for a provided avatarUrl
        else if (user.avatarUrl) {
            return user.avatarUrl;
        } 
        // Default avatar for users without a specific avatar
        else {
            return '/path-to-default-avatar.jpg';
        }
    } 
    // Default avatar for cases where user object is not provided
    else {
        return '/path-to-default-avatar.jpg';
    }
};

  // Helper function to format the date in various styles
  const formatDate = (dateString, formatType = 1) => {
    const date = new Date(dateString);
    
    switch (formatType) {
      case 1: // Format: DD/MM/YYYY
        return date.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
      case 2: // Format: 9 August
        return `${date.getDate()} ${date.toLocaleDateString('en-GB', { month: 'long' })}`;
      case 3: // Format: DD/MM/YYYY, Thursday
        const dayOfWeek = date.toLocaleDateString('en-GB', { weekday: 'long' });
        const formattedDate = date.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
        return `${formattedDate}, ${dayOfWeek}`;
      default:
        return dateString; // Fallback to raw date string
    }
  };

  // Get the latest upcoming approved leave by sorting and filtering
  const getLatestUpcomingLeave = (leaves) => {
    const now = new Date();

    // Filter approved and upcoming leaves
    const upcomingApprovedLeaves = leaves.filter(leave =>
      leave.status === "Approved" && new Date(leave.dateTo) >= now
    );

    if (upcomingApprovedLeaves.length === 0) return null;

    // Sort by dateFrom (ascending) to get the soonest upcoming leave
    upcomingApprovedLeaves.sort((a, b) => new Date(a.dateFrom) - new Date(b.dateFrom));

    // Return the latest upcoming approved leave
    return upcomingApprovedLeaves[0];
  };

  const latestLeave = leaves && leaves.length > 0 ? getLatestUpcomingLeave(leaves) : null;
  const user = latestLeave ? latestLeave.user : null; // Extract user from the latest leave

  return (
    <Fragment>
      <div className="lc-main">
        <div className="lc-title">
          <div className="adm-db-people-title">Leave Management</div>
          <div><Link to="/leave-setting" className="icon-reset"><BsArrowUpRightCircle /></Link></div>
        </div>

        <div>
          {loading ? (
            <div>Loading...</div> 
          ) : latestLeave ? (
            <div className="lc-content">
              <section>
                <div className="lc-c-img">
                  <img 
                    src={loadAvatar(user)} 
                    alt={user.name || 'User Avatar'} 
                    className="user-avatar"
                  />
                </div>
                <div className="lc-c-user">
                    <div>{user.name}</div>
                    <div>Type: {latestLeave.leaveType} Leave</div>
                    <div>From: {formatDate(latestLeave.dateFrom, 3)} &#x2192; {formatDate(latestLeave.dateTo, 3)} </div>
                </div>
              </section>
              <section>
                <div className="lc-date-icon">{formatDate(latestLeave.dateFrom, 2)}</div>
                <div className="lc-reason">{latestLeave.reason}</div>
              </section>
            </div>
          ) : (
            <div>Leave Records Not Available</div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  leave: state.leave
});

export default connect(mapStateToProps, { fetchPendingLeaves })(Leave);
